import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MapProvider } from './providers/MapProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { Provider } from 'react-redux'
// import store from './store'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MapProvider>
        <App />
      </MapProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  // <Provider store={store}>
  // <App />,
  // </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
